import React from 'react';
import Layout from '../components/layout';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import SEO from '../components/seo';

const Project = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const StyledProject = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .container {
      display: flex;
      justify-content: space-between;
      margin: 2rem auto 4rem;
      .content {
        width: 65%;
        h1 {
          color: ${props => props.theme.colors.org};
          font-size: 3rem;
          margin-bottom: 3rem;
        }
        p {
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: ${props => props.theme.colors.grey2};
          font-family: ${props => props.theme.fonts.sec};
          text-align: justify;
        }
      }
      .project-details {
        width: 30%;
        h2 {
          font-size: 2.2rem;
        }
        ul {
          list-style: none;
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1rem 2rem;
            &:nth-child(odd) {
              background-color: #f2f2f2;
            }
            h4 {
              font-size: 1.4rem;
              color: #333333;
            }
            p {
              font-size: 1.4rem;
              font-family: ${props => props.theme.fonts.sec};
              color: #555555;
            }
          }
        }
      }
      .summary {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .block {
          width: 40%;
          padding: 1rem 0;
          font-size: 1.8rem;
          color: #333333;
          p {
            font-size: 1.5rem;
            font-family: ${props => props.theme.fonts.sec};
            color: #555555;
            text-align: justify;
          }
        }
      }
      .gallery {
        width: 100%;
        margin-bottom: 3rem;
        h2 {
          font-size: 2rem;
          color: #333333;
          margin-bottom: 3rem;
        }
        .gatsby-image-wrapper {
          height: 42rem;
        }
      }
    }
    @media (max-width: 768px) {
      .container {
        flex-direction: column;
        .content {
          width: 100%;
        }
        .project-details {
          width: 100%;
        }
        .summary {
          flex-direction: column;
          .block {
            width: 100%;
          }
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO title={data.wordpressWpProjects.acf.project_name} />
      <StyledProject>
        <div className="top-bg">
          <Img
            fluid={
              data.wordpressWpProjects.acf.project_image.localFile
                .childImageSharp.fluid
            }
          />
        </div>
        <div className="container">
          <div className="content">
            <h1>{data.wordpressWpProjects.acf.project_name}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: data.wordpressWpProjects.acf.project_details
              }}
            />
          </div>
          <div className="project-details">
            <h2>Project Details</h2>
            <ul>
              {data.wordpressWpProjects.acf.finish_date && (
                <li>
                  <h4>Finish Date</h4>
                  <p>{data.wordpressWpProjects.acf.finish_date}</p>
                </li>
              )}
              {data.wordpressWpProjects.acf.client_name && (
                <li>
                  <h4>Client</h4>
                  <p>{data.wordpressWpProjects.acf.client_name}</p>
                </li>
              )}
              {data.wordpressWpProjects.acf.project_category && (
                <li>
                  <h4>Category</h4>
                  <p>{data.wordpressWpProjects.acf.project_category}</p>
                </li>
              )}
              {data.wordpressWpProjects.acf.location && (
                <li>
                  <h4>Location</h4>
                  <p>{data.wordpressWpProjects.acf.location}</p>
                </li>
              )}
              {data.wordpressWpProjects.acf.project_cost && (
                <li>
                  <h4>Prject Cost</h4>
                  <p>${data.wordpressWpProjects.acf.project_cost}</p>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="summary">
            <div className="block">
              <h2>Project Summary</h2>
              <p>{data.wordpressWpProjects.acf.project_summary}</p>
            </div>
            <div className="block">
              <h2>Final Results</h2>
              <p>{data.wordpressWpProjects.acf.final_results}</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="gallery">
            <h2>Project Photos</h2>
            <Slider {...settings}>
              <Img
                fluid={
                  data.wordpressWpProjects.acf.project_photos_one.localFile
                    .childImageSharp.fluid
                }
              />
              <Img
                fluid={
                  data.wordpressWpProjects.acf.project_photos_two.localFile
                    .childImageSharp.fluid
                }
              />
              {data.wordpressWpProjects.acf.project_photos_three && (
                <Img
                  fluid={
                    data.wordpressWpProjects.acf.project_photos_three.localFile
                      .childImageSharp.fluid
                  }
                />
              )}
              {data.wordpressWpProjects.acf.project_photos_four && (
                <Img
                  fluid={
                    data.wordpressWpProjects.acf.project_photos_four.localFile
                      .childImageSharp.fluid
                  }
                />
              )}
            </Slider>
          </div>
        </div>
      </StyledProject>
    </Layout>
  );
};

export const data = graphql`
  query($slug: String!) {
    wordpressWpProjects(slug: { eq: $slug }) {
      acf {
        project_name
        project_details
        finish_date
        client_name
        location
        project_cost
        project_summary
        final_results
        project_category
        project_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        project_photos_one {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        project_photos_two {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        project_photos_three {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        project_photos_four {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
